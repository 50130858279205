import './SideBar.scss';

import chevronRight from '../../assets/images/icons/chevron-right.svg';
import moderatorIcon from '../../assets/images/icons/sidebar-icons/user.svg';
import usersIcon from '../../assets/images/icons/sidebar-icons/user-id.svg';
import venuesIcon from '../../assets/images/icons/sidebar-icons/venue.svg';
import eventsIcon from '../../assets/images/icons/sidebar-icons/events.svg';
import queriesIcon from '../../assets/images/icons/sidebar-icons/queries.svg';
import sponsorIcon from '../../assets/images/icons/sidebar-icons/wallet.svg';
import advertIcon from '../../assets/images/icons/sidebar-icons/adverts.svg';
import promoIcon from '../../assets/images/icons/sidebar-icons/promotions.svg';
import settingsIcon from '../../assets/images/icons/sidebar-icons/settings.svg';
import organizerIcon from '../../assets/images/icons/sidebar-icons/organizer.svg';
import { NavLink } from 'react-router-dom';

function SideBar() {

    const sideBarContent = [
        {
            id: 1,
            avatar: moderatorIcon,
            text: 'Moderator',
            url: 'moderators'
        },
        {
            id: 2,
            avatar: usersIcon,
            text: 'Users',
            url: 'users'
        },
        {
            id: 12,
            avatar: organizerIcon,
            text: 'Organizer Requests',
            url: 'organiser-requests'
        },
        {
            id: 3,
            avatar: venuesIcon,
            text: 'Business',
            url: 'business'
        },
        {
            id: 4,
            avatar: eventsIcon,
            text: 'Events',
            url: 'events'
        },
        {
            id: 5,
            avatar: queriesIcon,
            text: 'Queries',
            url: 'queries'
        },
        {
            id: 6,
            avatar: sponsorIcon,
            text: 'Guestlist',
            url: 'spotlight'
        },
        // {
        //     id: 7,
        //     avatar: sponsorIcon,
        //     text: 'Liquor Sponsorships',
        //     url: 'liquor'
        // },
        {
            id: 8,
            avatar: advertIcon,
            text: 'Ad Management',
            url: 'ad-management'
        },
        // {
        //     id: 9,
        //     avatar: promoIcon,
        //     text: 'Promotion',
        //     url: 'promotion'
        // },
        {
            id: 10,
            avatar: promoIcon,
            text: 'Promoters',
            url: 'promoters/list'
        },
        {
            id: 11,
            avatar: settingsIcon,
            text: 'Settings',
            url: 'settings'
        }
    ]

    return (
        <article className='sidebar'>
            {
                sideBarContent.map(item => (
                    <NavLink to={`/${item.url}`} key={item.id} className='sidebar__item'>
                        <div className='sidebar__item-left'>
                            <img className='sidebar__item-left-icon' src={item.avatar} alt={`${item.text} icon`}></img>
                            <p className='sidebar__item-left-writing'>
                                {item.text}
                            </p>
                        </div>

                        <div className='sidebar__item-right'>
                            <img className='sidebar__item-right-icon' src={chevronRight} alt='right chevron'></img>
                        </div>
                    </NavLink>
                ))
            }
        </article>
    )
}

export default SideBar;