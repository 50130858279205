import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { format } from 'date-fns';
import parseMinutesToHourString from '../../../utils/parseMinutesToHourString';
import api from '../../../services/api';
import LoadingPage from '../../LoadingPage/LoadingPage';
import InputForm from '../../../components/InputForm/InputForm';
import CustomDatePicker from '../../../components/CustomDatePicker';
import TimePicker from '../../../components/TimePicker/TimePicker';
import {
    ContainerButtons,
    ContainerDate,
    ContainerError,
    ContainerForm,
    ContainerHeader,
    ContainerMessageInfo,
    ContainerStartDate,
    ErrorMessage,
    MessageInfo,
} from './styles';
import { MdEdit } from 'react-icons/md';

const EditTicketSales = () => {
    const { ticketId } = useParams();

    const location = useLocation();

    const [selectedEvents, setSelectedEvents] = useState('');
    const [startHours, setStartHours] = useState();
    const [selectedDateEnd, setSelectedDateEnd] = useState(null);
    const [selectedDateStart, setSelectedDateStart] = useState(null);
    const [endHours, setEndHours] = useState();
    const selectedPeriod = 'CUSTOM';
    const [amountOfTickets, setAmountOfTickets] = useState();
    const [ticketPrice, setTicketPrice] = useState();
    const [freeDrinkVoucher, setFreeDrinkVoucher] = useState();
    const navigate = useNavigate();
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(true);

    const validationSchema = Yup.object().shape({
        ticketPrice: Yup.number()
            .typeError('Must be a number')
            .required('Ticket price is required')
            .min(0, 'Ticket price cannot be negative'),
        freeDrinkVoucher: Yup.number()
            .typeError('Must be a number')
            .required('Free drink voucher is required')
            .min(0, 'Free drink voucher cannot be negative')
            .integer('Free drink voucher must be an integer'),
        selectedDateStart: Yup.string().required('Start date is required'),
        selectedDateEnd: Yup.string().required('End date is required'),
        startHours: Yup.number()
            .typeError('Must be a number')
            .required('Time is required')
            .min(0, 'Time cannot be negative')
            .integer('Time must be an integer'),
        endHours: Yup.number()
            .typeError('Must be a number')
            .required('Time is required')
            .min(0, 'Time cannot be negative')
            .integer('Time must be an integer'),
    });

    const handleFormSubmit = async (event) => {
        event.preventDefault();
        setErrors({});

        try {
            await validationSchema.validate(
                {
                    selectedDateEnd,
                    selectedDateStart,
                    ticketPrice,
                    freeDrinkVoucher,
                    startHours,
                    endHours,
                },
                { abortEarly: false }
            );

            const formattedStartDate = selectedDateStart
                ? format(selectedDateStart, 'yyyy-MM-dd')
                : '';
            const formattedEndDate = selectedDateEnd
                ? format(selectedDateEnd, 'yyyy-MM-dd')
                : '';

            const newSpotlight = {
                ticketId: ticketId,
                amountOfTickets: amountOfTickets,
                price: ticketPrice,
                period: selectedPeriod,
                freeDrinkVouchers: freeDrinkVoucher,
                ...(selectedPeriod === 'CUSTOM' && {
                    dateStart: formattedStartDate,
                    hourStart: startHours,
                    dateEnd: formattedEndDate,
                    hourEnd: endHours,
                }),
            };

            await api.put(`/ticket-sales/${ticketId}`, newSpotlight, {
                params: {
                    offset: new Date().getTimezoneOffset(),
                },
            });

            toast.success('Ticket Added Successfully!');

            setErrors({});
            navigate('/sell-tickets/list');
        } catch (err) {
            console.log(err);
            if (err.response && err.response.data && err.response.data.message)
                toast.error(`${err.response.data.message}`);

            if (err instanceof Yup.ValidationError) {
                setErrors(
                    err.inner.reduce((errorObj, currentError) => {
                        return {
                            ...errorObj,
                            [currentError.path]: currentError.message,
                        };
                    }, {})
                );
            }
        }
    };

    useEffect(() => {
        setLoading(true);
        const getTicketListId = async () => {
            try {
                const response = await api.get(`/ticket-sales/${ticketId}`, {
                    params: {
                        offset: new Date().getTimezoneOffset(),
                    },
                });

                const data = response.data;

                setTicketPrice(data.price / 100);
                setFreeDrinkVoucher(data.vouchers_total_quantity);
                setSelectedDateStart(data.start_date);
                setSelectedDateEnd(data.end_date);

                setAmountOfTickets(data.tickets_total);

                setSelectedEvents(data.event_name);

                setEndHours(Number(data.end_hour));
                setStartHours(Number(data.start_hour));

                console.log(data);
                setLoading(false);
            } catch (error) {
                console.log(error);
            }
        };

        getTicketListId();
    }, [ticketId]);

    const handleDateEndChange = (date) => {
        setSelectedDateEnd(date);
    };
    const handleDateStartChange = (date) => {
        setSelectedDateStart(date);
    };

    const handleEvents = (selectedOption) => {
        setSelectedEvents(selectedOption);
    };

    const handleStartTimeChange = ({ value }) => {
        setStartHours(value);
    };

    const handleEndTimeChange = ({ value }) => {
        setEndHours(value);
    };

    const startTimeValue = parseMinutesToHourString(startHours || 0);
    const endTimeValue = parseMinutesToHourString(endHours || 0);

    return (
        <>
            <ContainerHeader>
                {location.pathname !== "/sell-tickets" && (
                    <Link to={`/sell-tickets/list`}>
                        {/*<img src={chevronLeft} alt="" />*/}
                    </Link>
                )}
                <h1>Edit Ticket sales</h1>

                {/* <Button backgroundColor="#0000" onClick={() => {}}>
      Deactivate event
    </Button> */}
            </ContainerHeader>

            {loading ? (
                <>
                    <LoadingPage />
                </>
            ) : (
                <ContainerForm onSubmit={handleFormSubmit}>
                    <div>
                        <div>
                            <InputForm
                                id="eventName"
                                label="Event name"
                                value={selectedEvents}
                                onChange={handleEvents}
                                error={errors.selectedEvents}
                                isDisabled
                            />

                            <ContainerMessageInfo>
                                <InputForm
                                    type="number"
                                    id="amountOfTickets"
                                    label="Amount of tickets "
                                    placeholder="Enter total"
                                    value={amountOfTickets}
                                    onChange={(event) => {
                                        setAmountOfTickets(event.target.value);
                                    }}
                                    error={errors.amountOfTickets}
                                />

                                <MessageInfo>
                                    Leave blank for unlimited amount of tickets
                                </MessageInfo>
                            </ContainerMessageInfo>
                        </div>

                        <div>
                            <InputForm
                                type="number"
                                placeholder="Enter amount"
                                id="ticketPrice"
                                label="Ticket price"
                                value={ticketPrice}
                                onChange={(event) => setTicketPrice(event.target.value)}
                                error={errors.ticketPrice}
                            />

                            <ContainerMessageInfo>
                                <InputForm
                                    type="number"
                                    id="freeDrinkVoucher"
                                    label="Free Drink Vouchers Per Person"
                                    value={freeDrinkVoucher}
                                    onChange={(event) => {
                                        setFreeDrinkVoucher(event.target.value);
                                    }}
                                    error={errors.freeDrinkVoucher}
                                />

                                <MessageInfo>“0” for no vouchers</MessageInfo>
                            </ContainerMessageInfo>
                        </div>
                    </div>

                    <ContainerDate>
                        <ContainerStartDate>
                            <h1>Start</h1>

                            <div>
                                <CustomDatePicker
                                    label="Date"
                                    selectedDate={selectedDateStart}
                                    onChange={handleDateStartChange}
                                    error={errors.selectedDateStart}
                                    defaultValue={undefined}
                                />

                                <div>
                                    <ContainerError>
                                        <h1>Time</h1>
                                        <ErrorMessage>{errors.startHours}</ErrorMessage>
                                    </ContainerError>

                                    <TimePicker
                                        onChangeTime={handleStartTimeChange}
                                        defaultValue={startTimeValue}
                                        label="Time"
                                        error={errors.startHours}
                                    />
                                </div>
                            </div>

                            <MessageInfo>
                                *Select the start date and time that you would like to open the
                                window for ticket sales.
                            </MessageInfo>
                        </ContainerStartDate>

                        <ContainerStartDate>
                            <h1>End</h1>

                            <div>
                                <CustomDatePicker
                                    label="Date"
                                    selectedDate={selectedDateEnd}
                                    onChange={handleDateEndChange}
                                    error={errors.selectedDateEnd}
                                    defaultValue={undefined}
                                />

                                <div>
                                    <ContainerError>
                                        <h1>Time</h1>
                                        <ErrorMessage>{errors.endHours}</ErrorMessage>
                                    </ContainerError>
                                    <TimePicker
                                        label="Time"
                                        error={errors.endHours}
                                        onChangeTime={handleEndTimeChange}
                                        defaultValue={endTimeValue}
                                    />
                                </div>
                            </div>

                            <MessageInfo>
                                *Select the end date and time that you would like to close the                window for ticket sales.
                            </MessageInfo>
                        </ContainerStartDate>
                    </ContainerDate>

                    <ContainerButtons>
                        <button type="submit">
                            Save
                            <MdEdit size={24} color="#fff" />
                        </button>
                    </ContainerButtons>
                </ContainerForm>
            )}
        </>
    );
};

export default EditTicketSales;