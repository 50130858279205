import "./EventsUpcoming.scss";

import { useEffect, useState } from "react";
import { MdEdit } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";

import TableColumn from "../../components/TableColumn/TableColumn";
import api from "../../services/api";
import parseDate from "../../utils/parseDate";
import LoadingPage from "../LoadingPage/LoadingPage";

const EventsUpcoming = () => {
    const navigate = useNavigate();

    const sorts = ["Name", "Category", "Music"];
    const headings = [
        "Name",
        "Date",
        "Event hours",
        "Likes",
        "Check ins",
        "Review Guestlist",
        "Review Ticket sales",
    ];
    const records = 10;
    const [events, setEvents] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(
        Number(localStorage.getItem("ListCurrentPageEventsUpcoming")) || 1
    );
    const [isSearching, setIsSearching] = useState(false);
    const [sortBy, setSortBy] = useState(null);
    const [sortDirection, setSortDirection] = useState("null");

    function formatTime(minutes) {
        const hours = Math.floor(minutes / 60);
        const mins = minutes % 60;
        const ampm = hours >= 12 ? "PM" : "AM";
        const hour12 = hours % 12 || 12;
        const minuteFormatted = mins.toString().padStart(2, "0");
        return `${hour12}:${minuteFormatted} ${ampm}`;
    }

    useEffect(() => {
        const fetchData = async (page, searchTerm) => {
            setIsSearching(true)
            try {
                const url = searchTerm ? `/events/search` : `/events`;

                const response = await api.get(url, {
                    params: {
                        name: searchTerm || '',
                        page: page,
                        records: records,
                        // sort_column: sortBy,
                        // sort: sortDirection,
                        offset: new Date().getTimezoneOffset(),
                    },
                });

                const formattedEvents = response.data.data.map((item) => {
                    const { from, to } = item.hours;

                    return {
                        id: item._id,
                        Venue: item.venue_name,
                        Name: item.name,
                        Category: item.tags.map((tag) => tag).join(", "),
                        Location: item.location,
                        Date: parseDate(item.date),
                        Dress: item.dress,
                        Music: item.music.map((music) => music).join(", "),
                        Address: item.address,
                        About:
                            item.description.length > 50
                                ? `${item.description.slice(0, 50)}...`
                                : item.description,
                        Likes: item.number_of_saves,
                        Guestlist: item.check_ins,

                        "Event hours": `${formatTime(from)} - ${formatTime(to)}`,
                        "Check ins": item.check_ins,
                        "Review Guestlist": (
                            <Link
                                to={`/events/review-guestlist/${item._id}`}
                                style={{ color: "rgba(167, 41, 245, 1)" }}
                            >
                                {item.guest_list_count}
                            </Link>
                        ),
                        "Review Ticket sales": (
                            <Link
                                to={`/events/review-tickets/${item._id}`}
                                style={{ color: "rgba(167, 41, 245, 1)" }}
                            >
                                {item.ticket_sales_count}
                            </Link>
                        ),
                    };
                });

                setTotalPages(response.data.total);
                setEvents(formattedEvents);
            } catch (error) {
                console.log(`events get request didn't work. error: ${error}`);
            } finally {
                setIsSearching(false);
            }
        };

        fetchData(currentPage, searchTerm);
    }, [sortBy, sortDirection, currentPage, searchTerm]);

    const actions = [
        {
            label: <MdEdit size={30} color="rgba(167, 41, 245, 1)" />,
            onClick: (rowData) => {
                navigate(`/events/edit/${rowData.id}`);
            },
        },
    ];

    const handleChange = (event) => {
        const value = event.target.value;
        setSearchTerm(value);
        setCurrentPage(0);
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
        localStorage.setItem("ListCurrentPageEventsUpcoming", page);
    };

    const fetchAllData = async () => {
        setIsSearching(true);
        try {
            const response = await api.get(`/events`, {
                params: {
                    records: 9999999999,
                },
            });

            return response.data.data.map((item) => ({
                id: item._id,
                Venue: item.venue_name,
                Name: item.name,
                Category: item.tags.map((tag) => tag).join(", "),
                Location: item.location,
                Date: parseDate(item.date),
                Dress: item.dress,
                Music: item.music.map((music) => music).join(", "),
                Address: item.address,
                About:
                    item.description.length > 50
                        ? `${item.description.slice(0, 50)}...`
                        : item.description,
                Likes: item.number_of_saves,
                Guestlist: item.check_ins,
            }));
        } catch (error) {
            console.log(`Error fetching all data: ${error}`);
            return [];
        } finally {
            setIsSearching(false);
        }
    };

    useEffect(() => {
        const storedPage = localStorage.getItem("ListCurrentPageEventsUpcoming");

        setCurrentPage(Number(storedPage) || 1);
    }, []);

    const sortColumnsMapping = {
        Name: "name",
        Category: "tags",
        Music: "music",
    };

    const handleSort = (columnName) => {
        const backendColumnName = sortColumnsMapping[columnName];
        if (sortBy === backendColumnName) {
            setSortDirection(sortDirection === "asc" ? "desc" : "asc");
        } else {
            setSortBy(backendColumnName);
            setSortDirection("asc");
        }
    };

    return (
        <section className="upcoming">
            {
                isSearching? (<LoadingPage/>) : (
                    <TableColumn
                        hasHeader={true}
                        columns={headings}
                        data={events}
                        sortableColumns={sorts}
                        hasExport={true}
                        hasSearch={true}
                        totalPages={totalPages}
                        currentPage={currentPage}
                        onPageChange={handlePageChange}
                        records={records}
                        actions={actions}
                        inputSearchProps={{value: searchTerm, onChange: handleChange}}
                        fetchAllData={fetchAllData}
                        onSort={handleSort}
                        hasModifyHeader={true}
                    />

                )
            }
        </section>
    );
};

export default EventsUpcoming;
