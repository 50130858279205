import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MdEdit } from "react-icons/md";

import TableColumn from "../../../../components/TableColumn/TableColumn";
import api from "../../../../services/api";
import parseDate from "../../../../utils/parseDate";
import LoadingPage from "../../../../components/LoadingPage/LoadingPage";

const ListBarCrawlEvent = () => {
  const navigate = useNavigate();

  const sorts = ["Name", "Category", "Music"];
  const headings = [
    "Name",
    "Ticket Price",
    "Tickets Quantity",
    "Date",
    "Description",
  ];
  const [events, setEvents] = useState([]);
  const [isSearching, setIsSearching] = useState(false);

  const [searchTerm, setSearchTerm] = useState("");

  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(
    Number(localStorage.getItem("ListCurrentPageEventsBarCrawl")) || 1
  );
  const records = 10;

  const [sortBy, setSortBy] = useState(null);
  const [sortDirection, setSortDirection] = useState("null");

  useEffect(() => {
    const fetchData = async (page, searchTerm) => {
      setIsSearching(true);
      try {
        const url = searchTerm
          ? `/featured-events/admin-list`
          : `/featured-events/admin-list`;

        const response = await api.get(url, {
          params: {
            page: page,
            records: records,
            // sort_column: sortBy,
            // sort: sortDirection,
          },
        });

        const formattedEvents = response.data.data.map((item) => ({
          _id: item?._id,
          Name: item?.name || "",
          "Ticket Price": item?.ticket_price / 100,
          "Tickets Quantity":
            item?.tickets_total === 999999
              ? "unlimited"
              : item?.tickets_total,
          Date: parseDate(item.start_date),
          Description: item?.description ? item?.description : "---",
          featuredEventId: item?.featured_event_id,
          featuredEventType: item?.featured_event_type
        }));

        setTotalPages(response.data?.total);
        setEvents(formattedEvents);
      } catch (error) {
        console.log(`events get request didn't work. error: ${error}`);
      } finally {
        setIsSearching(false);
      }
    };
    fetchData(currentPage, searchTerm);
  }, [sortBy, sortDirection, currentPage, searchTerm]);

  const actions = [
    {
      label: <MdEdit size={30} color="rgba(167, 41, 245, 1)" />,
      onClick: (rowData) => {
        console.log(rowData);
        if (rowData.featuredEventType === 'BarCrawl') {
          navigate(`/bar-crawl-event/edit/${rowData.featuredEventId}`);
        } else if (rowData.eventType === 'Ticket Sale') {
          navigate(`/ticket-sales/edit/${rowData.featuredEventId}`);
        }
      },
    },
  ];;

  useEffect(() => {
    setCurrentPage(0);
  }, [searchTerm]);

  const handleChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    localStorage.setItem("ListCurrentPageEventsBarCrawl", page);
  };

  const fetchAllData = async () => {
    try {
      const response = await api.get(`/featured-events/admin-list`, {
        params: {
          records: 9999999999,
        },
      });

      return response.data.data.map((item) => ({
        Name: item?.name,
        "Ticket Price": item?.ticket_price,
        "Tickets Quantity": item?.tickets_total,

        Date: parseDate(item?.start_date),

        Description: item?.description,
      }));
    } catch (error) {
      console.log(`Error fetching all data: ${error}`);
      return [];
    }
  };

  useEffect(() => {
    const storedPage = localStorage.getItem("ListCurrentPageEventsBarCrawl");

    setCurrentPage(Number(storedPage) || 1);
  }, []);

  const sortColumnsMapping = {
    Name: "name",
    Category: "tags",
    Music: "music",
  };

  const handleSort = (columnName) => {
    const backendColumnName = sortColumnsMapping[columnName];
    if (sortBy === backendColumnName) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortBy(backendColumnName);
      setSortDirection("asc");
    }
  };

  return (
      <div>
        {isSearching ? (
            <LoadingPage/>
        ) : (
            <TableColumn
                hasHeader={true}
                columns={headings}
                data={events}
                sortableColumns={sorts}
                hasExport={true}
                hasSearch={true}
                totalPages={totalPages}
                currentPage={currentPage}
                onPageChange={handlePageChange}
                records={records}
                actions={actions}
                inputSearchProps={{value: searchTerm, onChange: handleChange}}
                fetchAllData={fetchAllData}
                onSort={handleSort}
                hasModifyHeader={true}
                isSearching={isSearching}
            />
        )}
      </div>
  );
};

export default ListBarCrawlEvent;
