import { format } from "date-fns";
import { useCallback, useEffect, useState } from "react";
import { MdCheck } from "react-icons/md";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { v4 } from "uuid";
import * as Yup from "yup";

import chevronLeft from "../../../../assets/images/icons/chevron-left.svg";
import CustomDatePicker from "../../../../components/CustomDatePicker";
import Image from "../../../../components/Image";
import InputForm from "../../../../components/InputForm/InputForm";
import LoadingPage from "../../../../components/LoadingPage/LoadingPage";
import RadioButton from "../../../../components/RadioButton/RadioButton";
import SelectForm from "../../../../components/SelectForm/SelectForm";
import SideBar from "../../../../components/SideBar/SideBar";
import TextAreaForm from "../../../../components/TextAreaForm";
import TimePicker from "../../../../components/TimePicker/TimePicker";
import TopNav from "../../../../components/TopNav/TopNav";
import api from "../../../../services/api";
import parseDate from "../../../../utils/parseDate";
import CustomPerVenue from "../../NewBarCrawlEvent/New/CustomPerVenue";
import TotalForDurationEvent from "../../NewBarCrawlEvent/New/TotalForDurationEvent";
import TotalPerVenue from "../../NewBarCrawlEvent/New/TotalPerVenue";
import {
  ContainerAddVenue,
  ContainerButtons,
  ContainerDate,
  ContainerForm,
  ContainerHeader,
  ContainerImg,
  ContainerRadioButton,
  ContainerTickets,
} from "./styles";
import { SwitchContainer } from "../New/styles";
import parseMinutesToHourString from "../../../../utils/parseMinutesToHourString";

const formatTime = (hours) => {
  return hours !== undefined
    ? `${String(Math.floor(hours / 60)).padStart(2, "0")}:${String(
      hours % 60
    ).padStart(2, "0")}`
    : "";
};

const EditBarCrawlEvent = () => {
  const navigate = useNavigate();

  const { eventId } = useParams();

  const [errors, setErrors] = useState({});

  const [barCrawlForId, setBarCrawlForId] = useState();
  const [amountOfTickets, setAmountOfTickets] = useState("");
  const [ticketPrice, setTicketPrice] = useState("");
  const [additionalInformation, setAdditionalInformation] = useState("");

  const [selectedDateEnd, setSelectedDateEnd] = useState(null);
  const [selectedDateStart, setSelectedDateStart] = useState(null);
  const [startHours, setStartHours] = useState(0);
  const [endHours, setEndHours] = useState(0);

  const [eventName, setEventName] = useState("");

  const [selectedVenueOption, setSelectedVenueOption] = useState("");
  const [showContent, setShowContent] = useState(false);
  const [venues, setVenues] = useState([]);
  const [initialVenueAdded, setInitialVenueAdded] = useState(false);
  const [venueOptions, setVenueOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingBarCrawl, setLoadingBarCrawl] = useState(false);
  const [stayTuned, setStayTuned] = useState(false);
  const [locationOpts, setLocationOpts] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);

  const [selectedOptionsDrinkVouchers, setSelectedOptionsDrinkVouchers] =
    useState({});

  const startTimeValue = parseMinutesToHourString(startHours);
  const endTimeValue = parseMinutesToHourString(endHours);

  const optionsDrinkVouchersQty = [
    { label: 1, value: 1 },
    { label: 2, value: 2 },
    { label: 3, value: 3 },
    { label: 4, value: 4 },
    { label: 5, value: 5 },
    { label: 6, value: 6 },
    { label: 7, value: 7 },
    { label: 8, value: 8 },
    { label: 9, value: 9 },
    { label: 10, value: 10 },
  ];

  const optionsVenue = [
    {
      label: "Total for the duration of event",
      value: "DURATION",
    },
    { label: "Total per venue", value: "VENUE" },
    { label: "Custom per venue", value: "CUSTOM" },
  ];

  const handleDateEndChange = (date) => {
    setSelectedDateEnd(date);
  };

  const handleDateStartChange = (date) => {
    setSelectedDateStart(date);
  };

  const handleStartTimeChange = ({ value }) => {
    const numericValue = Number(value);

    if (!isNaN(numericValue)) {
      setStartHours(numericValue);
    }
  };

  const handleEndTimeChange = ({ value }) => {
    const numericValue = Number(value);

    if (!isNaN(numericValue)) {
      setEndHours(numericValue);
    }
  };

  const handleAdditionalInformation = (selectedOption) => {
    setAdditionalInformation(selectedOption);
  };

  const validationSchema = Yup.object().shape({
    eventName: Yup.string().required("Event name is required"),
    selectedDateStart: Yup.string().required("Start date is required"),
    selectedDateEnd: Yup.string().required("End date is required"),
    startHours: Yup.string().required("Start hour is required"),
    endHours: Yup.string().required("End hour is required"),
    // amountOfTickets: Yup.number()
    //   .required("Amount of tickets is required")
    //   .typeError("Amount of tickets must be a number")
    //   .min(0, "Amount of tickets cannot be negative"),
    // ticketPrice: Yup.number()
    //   .required("Ticket Price is required")
    //   .typeError("Ticket Price is required must be a number")
    //   .min(0, "Amount of tickets cannot be negative"),
    selectedOptionsDrinkVouchers: Yup.string().required(
      "Drink Vouchers is required"
    ),
  });

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setErrors({});
    setLoading(true);

    try {
      await validationSchema.validate(
        {
          eventName,
          selectedOptionsDrinkVouchers: selectedOptionsDrinkVouchers?.value,
          selectedDateStart,
          selectedDateEnd,
          startHours,
          endHours,
          amountOfTickets,
          ticketPrice,
          location: selectedLocation?.value,
        },
        { abortEarly: false }
      );

      const formattedStartDate = selectedDateStart
        ? format(selectedDateStart, "yyyy-MM-dd")
        : "";
      const formattedEndDate = selectedDateEnd
        ? format(selectedDateEnd, "yyyy-MM-dd")
        : "";

      const newSpotlight = {
        name: eventName,
        vouchersQuantity: selectedOptionsDrinkVouchers?.value || "",
        voucherRule: selectedVenueOption,
        startDate: formattedStartDate,
        startHour: startHours,
        endDate: formattedEndDate,
        endHour: endHours,
        ticketQuantity: amountOfTickets,
        ticketPrice: ticketPrice,
        description: additionalInformation,
        customVouchersQuantity: "",
        venues: [],
        emptyVenues: stayTuned,
        location: selectedLocation?.value,
      };

      const getSelectedValues = (venues) =>
        venues
          .map(({ selectedValue }) => selectedValue?.value)
          .filter((value) => value != null);

      const getCustomVouchersQuantity = (venues) =>
        venues.reduce((acc, { voucherQuantity, selectedValue }) => {
          if (voucherQuantity && selectedValue) {
            acc[selectedValue.value] = voucherQuantity.value;
          }
          return acc;
        }, {});

      const isValidCustomVouchersQuantity = (venues) =>
        venues.every(
          ({ voucherQuantity, selectedValue }) =>
            voucherQuantity && selectedValue?.value != null
        );

      const hasValidSelectedValues = (venues) =>
        venues.every(({ selectedValue }) => selectedValue?.value != null);

      if (
        selectedVenueOption === "DURATION" ||
        selectedVenueOption === "VENUE"
      ) {
        if (!hasValidSelectedValues(venues)) {
          throw new Error("Please fill in all required fields.");
        }
        newSpotlight.venues = getSelectedValues(venues);
        delete newSpotlight.customVouchersQuantity;
      } else if (selectedVenueOption === "CUSTOM") {
        if (!isValidCustomVouchersQuantity(venues)) {
          throw new Error("Please fill in all required fields.");
        }

        newSpotlight.customVouchersQuantity = getCustomVouchersQuantity(venues);
        newSpotlight.venues = getSelectedValues(venues);
      }

      const formData = new FormData();

      Object.keys(newSpotlight).forEach((key) => {
        if (key === "customVouchersQuantity" || key === "venues") {
          if (Array.isArray(newSpotlight[key])) {
            newSpotlight[key].forEach((item) => {
              formData.append(`${key}[]`, item);
            });
          } else {
            Object.entries(newSpotlight[key]).forEach(([k, v]) => {
              formData.append(`${key}[${k}]`, v);
            });
          }
        } else {
          formData.append(key, newSpotlight[key]);
        }
      });

      if (file) {
        formData.append(`image`, file);
      }

      await api.put(`/bar-crawl/${eventId}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        params: { offset: new Date().getTimezoneOffset() },
      });

      setLoading(false);
      toast.success("Bar Crawl Edited Successfully");
      setErrors({});
      navigate("/events");
    } catch (error) {
      console.log(error);
      setLoading(false);

      if (error.response && error.response.data) {
        toast.error(`${error.response.data.message}`);
      } else {
        toast.error(`${error}`);
      }

      if (error instanceof Yup.ValidationError) {
        const formattedErrors = error.inner.reduce((errorObj, currentError) => {
          return {
            ...errorObj,
            [currentError.path]: currentError.message,
          };
        }, {});

        setErrors(formattedErrors);
      }
    }
  };

  const handleVenueOptionChange = (selectedOption) => {
    setSelectedVenueOption(selectedOption);
    setShowContent(false);
    setVenues([]);
    setInitialVenueAdded(false);
  };

  const handleAddVenueClick = () => {
    if (selectedVenueOption) {
      setVenues([...venues, { id: v4() }]);
      setInitialVenueAdded(true);
      setShowContent(true);
    }
  };

  const handleAddNewVenue = () => {
    if (venues.length < 10) {
      setVenues([...venues, { id: v4() }]);
    }
  };

  const handleRemoveVenue = (id) => {
    setVenues(venues.filter((venue) => venue.id !== id));
  };

  useEffect(() => {
    const LoadVenuesAll = async () => {
      try {
        const response = await api.get("/venues", {
          params: {
            records: 999999999,
          },
        });

        const venues = response.data.data;

        const formattedVenues = venues.map((venue) => ({
          label: venue.name,
          value: venue._id,
        }));

        setVenueOptions(formattedVenues);
      } catch (error) {
        console.error("Error loading venues:", error);
      }
    };

    LoadVenuesAll();
  }, []);

  const handleSelectedVenue = (venueId, selectedValue) => {
    setVenues((prevVenues) =>
      prevVenues.map((venue) =>
        venue.id === venueId ? { ...venue, selectedValue } : venue
      )
    );
  };

  const handleVoucherQuantityChange = (venueId, quantity) => {
    setVenues((prevVenues) =>
      prevVenues.map((venue) =>
        venue.id === venueId ? { ...venue, voucherQuantity: quantity } : venue
      )
    );
  };

  const [image, setImage] = useState("");
  const [file, setFile] = useState(null);

  const handleImageAdd = useCallback((event) => {
    const file = event.target.files[0];

    if (file) {
      const imageUrl = URL.createObjectURL(file);

      setFile(file);

      setImage(imageUrl);
    }
  }, []);

  const handleImageRemove = useCallback((id) => {
    setImage("");
    setFile(null);
  }, []);

  const findOption = (value, options) => {
    return options.find((option) => option.value === value) || options[0];
  };

  const [durationData, setDurationData] = useState([]);
  const [venueData, setVenueData] = useState([]);
  const [customData, setCustomData] = useState([]);

  useEffect(() => {
    const LoadBarCrawlId = async () => {
      setLoadingBarCrawl(true);
      try {
        const response = await api.get(`/bar-crawl/${eventId}`, {
          params: {
            offset: new Date().getTimezoneOffset(),
          },
        });
        const { data: filters } = await api.get('/filters');

        const locationOpts = filters.location.map(item => ({
          label: item,
          value: item,
        }));

        setLocationOpts(locationOpts);

        const data = response.data;

        setBarCrawlForId(data);
        setEventName(data.name);
        setSelectedOptionsDrinkVouchers(
          findOption(data.vouchers_quantity, optionsDrinkVouchersQty)
        );
        setAmountOfTickets(data.tickets_quantity);
        setTicketPrice(data.ticket_price / 100);
        setSelectedDateStart(parseDate(data.start_date));
        setSelectedDateEnd(parseDate(data.end_date));
        setStartHours(Number(data.start_hour));
        setEndHours(Number(data.end_hour));
        setAdditionalInformation(data.description);

        setImage(data.image_url);
        setStayTuned(data.empty_venues);
        setSelectedLocation(
          locationOpts.find(item => item.value === data.location) || null
        );

        if (data.total_for_duration) {
          setSelectedVenueOption("DURATION");

          setDurationData(
            data.venues.map((venue) => ({
              id: venue._id,
              selectedValue: { value: venue._id, label: venue.name },
            }))
          );
        } else if (data.total_per_venue) {
          setSelectedVenueOption("VENUE");

          setVenueData(
            data.venues.map((venue) => ({
              id: venue._id,
              selectedValue: { value: venue._id, label: venue.name },
            }))
          );
        } else if (data.custom_per_venue) {
          setCustomData(
            data.venues.map((venue) => ({
              id: venue._id,
              selectedValue: { value: venue._id, label: venue.name },
              voucherQuantity: {
                value: data?.vouchers_custom_quantity[venue._id] || 0,
                label: data?.vouchers_custom_quantity[venue._id] || 0,
              },
            }))
          );
          setSelectedVenueOption("CUSTOM");
        } else {
          setShowContent(false);
        }

        setLoadingBarCrawl(false);
      } catch (error) {
        console.error("Error loading bar crawl:", error);
      }
    };

    LoadBarCrawlId();
  }, [eventId]);

  useEffect(() => {
    switch (selectedVenueOption) {
      case "DURATION":
        setVenues(durationData);
        setShowContent(true);
        break;
      case "VENUE":
        setVenues(venueData);
        setShowContent(true);
        break;
      case "CUSTOM":
        setVenues(customData);
        setShowContent(true);
        break;
      default:
        setVenues([]);
        setShowContent(false);
        break;
    }

    setInitialVenueAdded(true);
  }, [selectedVenueOption, durationData, venueData, customData]);

  const handleTicketPriceChange = (event) => {
    const { value } = event.target;

    const numericValue = value.replace(/[^0-9.]/g, "");

    const [integerPart, decimalPart] = numericValue.split(".");

    const formattedValue = decimalPart
      ? `${integerPart}.${decimalPart.slice(0, 2)}`
      : integerPart;

    setTicketPrice(formattedValue);

    if (formattedValue !== numericValue) {
      setErrors({
        ticketPrice: "Ticket price cannot have more than two decimal places.",
      });
    } else {
      setErrors({ ticketPrice: "" });
    }
  };

  const handleAmountOfTicketsChange = (event) => {
    const { value } = event.target;

    const numericValue = value.replace(/[^0-9]/g, "");

    const integerValue = parseInt(numericValue, 10);

    const limitedValue = integerValue > 999999 ? 999999 : integerValue;

    setAmountOfTickets(limitedValue);

    if (integerValue === 999999) {
      setErrors({
        amountOfTickets: "Unlimited",
      });
    } else if (value.includes(".")) {
      setErrors({
        amountOfTickets: "Amount of tickets cannot have decimal places.",
      });
    } else {
      setErrors({
        amountOfTickets: "",
      });
    }
  };

  const handleChangeStayTuned = useCallback(() => {
    setVenues([]);
    setStayTuned(oldState => !oldState);
    setInitialVenueAdded(false);
    setShowContent(false);
  }, []);

  return (
    <>
      <main>
        <TopNav />
        <div className="main-container">
          <SideBar />

          <article
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <section className="edit-user">
              <ContainerHeader>
                <Link to={`/events`}>
                  <img src={chevronLeft} alt="" />
                </Link>

                <h1>Edit Featured Event</h1>
              </ContainerHeader>

              {loadingBarCrawl ? (
                <>
                  <LoadingPage />
                </>
              ) : (
                <>
                  <ContainerForm onSubmit={handleFormSubmit}>
                    <div>
                      <div>
                        <InputForm
                          id="eventName"
                          placeholder="Enter event name"
                          label="Event name"
                          value={eventName}
                          onChange={(event) => {
                            setEventName(event?.target.value);
                          }}
                          error={errors.eventName}
                        />
                      </div>

                      <div>
                        <SelectForm
                          label="Drink Voucher Qty"
                          options={optionsDrinkVouchersQty}
                          value={selectedOptionsDrinkVouchers}
                          onChange={(selectedOption) => {
                            if (selectedOption) {
                              setSelectedOptionsDrinkVouchers(selectedOption);
                            }
                          }}
                          error={errors.selectedOptionsDrinkVouchers}
                          isDisabled={selectedVenueOption === "CUSTOM"}
                        />
                      </div>
                    </div>

                    <ContainerRadioButton>
                      <RadioButton
                        value={selectedVenueOption}
                        options={optionsVenue}
                        onChange={handleVenueOptionChange}
                        error={errors.selectedVenueOption}
                      />
                    </ContainerRadioButton>

                    <SwitchContainer>
                      <div>
                        <button type="button" onClick={handleChangeStayTuned}>Stay tuned!</button>

                        <div
                          className={`slide-button ${stayTuned ? "checked" : ""}`}
                          onClick={handleChangeStayTuned}
                        >
                          <div className="slider"></div>
                        </div>
                      </div>

                      <SelectForm
                        label="Location"
                        options={locationOpts}
                        value={selectedLocation}
                        onChange={setSelectedLocation}
                        isDisabled={!stayTuned}
                      />
                    </SwitchContainer>

                    {!initialVenueAdded && (
                      <ContainerAddVenue>
                        <button type="button" onClick={handleAddVenueClick} disabled={stayTuned}>
                          Add Venue
                        </button>
                      </ContainerAddVenue>
                    )}

                    {showContent && selectedVenueOption === "DURATION" && (
                      <>
                        <TotalForDurationEvent
                          venues={venues}
                          handleSelectedVenue={handleSelectedVenue}
                          handleRemoveVenue={handleRemoveVenue}
                          handleAddNewVenue={handleAddNewVenue}
                          error={errors.venues}
                          venueOptions={venueOptions}
                          disabled={stayTuned}
                        />
                      </>
                    )}

                    {showContent && selectedVenueOption === "VENUE" && (
                      <>
                        <TotalPerVenue
                          selectedOptionsDrinkVouchers={
                            selectedOptionsDrinkVouchers
                          }
                          venues={venues}
                          handleSelectedVenue={handleSelectedVenue}
                          handleRemoveVenue={handleRemoveVenue}
                          handleAddNewVenue={handleAddNewVenue}
                          handleVoucherQuantityChange={
                            handleVoucherQuantityChange
                          }
                          error={errors.selectedEvents}
                          venueOptions={venueOptions}
                          disabled={stayTuned}
                        />
                      </>
                    )}

                    {showContent && selectedVenueOption === "CUSTOM" && (
                      <>
                        <CustomPerVenue
                          venues={venues}
                          selectedOptionsDrinkVouchers={
                            selectedOptionsDrinkVouchers
                          }
                          handleSelectedVenue={handleSelectedVenue}
                          handleRemoveVenue={handleRemoveVenue}
                          handleAddNewVenue={handleAddNewVenue}
                          handleVoucherQuantityChange={
                            handleVoucherQuantityChange
                          }
                          error={errors.selectedEvents}
                          venueOptions={venueOptions}
                          disabled={stayTuned}
                        />
                      </>
                    )}

                    <ContainerDate>
                      <div>
                        <h1>Start</h1>

                        <div>
                          <CustomDatePicker
                            label="Date"
                            selectedDate={selectedDateStart}
                            onChange={handleDateStartChange}
                            error={errors.selectedDateStart}
                          />

                          <TimePicker
                            label="Time"
                            error={errors.startHours}
                            onChangeTime={handleStartTimeChange}
                            defaultValue={startTimeValue}
                          />
                        </div>
                      </div>

                      <div>
                        <h1>End</h1>

                        <div>
                          <CustomDatePicker
                            label="Date"
                            selectedDate={selectedDateEnd}
                            onChange={handleDateEndChange}
                            error={errors.selectedDateEnd}
                          />

                          <TimePicker
                            label="Time"
                            error={errors.endHours}
                            onChangeTime={handleEndTimeChange}
                            defaultValue={endTimeValue}
                          />
                        </div>
                      </div>
                    </ContainerDate>

                    <ContainerTickets>
                      <div>
                        <InputForm
                          type="number"
                          id="amountOfTickets"
                          label="Amount of tickets"
                          value={amountOfTickets}
                          onChange={handleAmountOfTicketsChange}
                          error={errors.amountOfTickets}
                        />

                        <InputForm
                          type="number"
                          id="ticketPrice"
                          label="Ticket price"
                          value={ticketPrice}
                          onChange={handleTicketPriceChange}
                          error={errors.ticketPrice}
                        />
                      </div>

                      <div>
                        <TextAreaForm
                          onChange={handleAdditionalInformation}
                          value={additionalInformation}
                          label="Additional information"
                          placeholder="Description of the venue"
                        />
                      </div>
                    </ContainerTickets>

                    <ContainerImg>
                      <Image
                        onAdd={handleImageAdd}
                        defaultValue={image}
                        onRemove={handleImageRemove}
                      />
                    </ContainerImg>

                    <ContainerButtons>
                      <button
                        type="submit"
                        style={{ opacity: loading ? 0.5 : 1 }}
                      >
                        Save Featured Event
                        <MdCheck size={20} color="#fff" />
                      </button>

                      <Link to="/events">Cancel</Link>
                    </ContainerButtons>
                  </ContainerForm>
                </>
              )}
            </section>
          </article>
        </div>
      </main>
    </>
  );
};

export default EditBarCrawlEvent;
