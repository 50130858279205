import "./EventsPast.scss";

import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { MdEdit } from "react-icons/md";

import TableColumn from "../../components/TableColumn/TableColumn";
import api from "../../services/api";
import parseDate from "../../utils/parseDate";
import LoadingPage from "../LoadingPage/LoadingPage";

const EventsPast = () => {
    const navigate = useNavigate();

    const sorts = ["Name", "Category", "Music"];
    const headings = [
        "Name",
        "Date",
        "Event hours",
        "Likes",
        "Check ins",
        "Review Guestlist",
        "Review Ticket sales",
    ];
    const [events, setEvents] = useState([]);

    const [searchTerm, setSearchTerm] = useState("");

    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(
        Number(localStorage.getItem("ListCurrentPageEventsPast")) || 1
    );
    const [isSearching, setIsSearching] = useState(false);
    const records = 10;

    const [sortBy, setSortBy] = useState(null);
    const [sortDirection, setSortDirection] = useState("null");

    function formatTime(minutes) {
        const hours = Math.floor(minutes / 60);
        const mins = minutes % 60;
        const ampm = hours >= 12 ? "PM" : "AM";
        const hour12 = hours % 12 || 12;
        const minuteFormatted = mins.toString().padStart(2, "0");
        return `${hour12}:${minuteFormatted} ${ampm}`;
    }

    useEffect(() => {
        const fetchData = async (page, searchTerm) => {
            setIsSearching(true);

            try {
                const response = await api.get(`/events/past`, {
                    params: {
                        name: searchTerm,
                        page: page,
                        records: records,
                        // sort_column: sortBy,
                        // sort: sortDirection,
                        offset: new Date().getTimezoneOffset(),
                    },
                });

                const formattedEvents = response.data.data.map((item) => {
                    const { from, to } = item.hours;


                    return {
                        id: item._id,
                        Venue: item.venue_name,
                        Name: item.name,
                        Category: item.tags.map((tag) => tag).join(", "),
                        Location: item.location,
                        'Date': parseDate(item.date),
                        Dress: item.dress,
                        Music: item.music.map((music) => music).join(", "),
                        Address: item.address,
                        About:
                            item?.description?.length > 50
                                ? `${item.description.slice(0, 50)}...`
                                : item.description,
                        Likes: item.number_of_saves,
                        "Guestlist": item.check_ins,


                        "Event hours": `${formatTime(from)} - ${formatTime(to)}`,
                        "Check ins": item.check_ins,
                        "Review Guestlist": (
                            <Link
                                to={`/events/review-guestlist/${item._id}`}
                                style={{ color: "rgba(167, 41, 245, 1)" }}
                            >
                                {item.guest_list_count}
                            </Link>
                        ),
                        "Review Ticket sales": (
                            <Link
                                to={`/events/review-tickets/${item._id}`}
                                style={{ color: "rgba(167, 41, 245, 1)" }}
                            >
                                {item.ticket_sales_count}
                            </Link>
                        ),
                    }
                });
                setTotalPages(response.data.total);
                setEvents(formattedEvents);
            } catch (error) {
                console.log(`events get request didn't work. error: ${error}`);
            } finally {
                setIsSearching(false);
            }
        };
        fetchData(currentPage, searchTerm)
    }, [sortBy, sortDirection, currentPage, searchTerm])

    const actions = [
        {
            label: <MdEdit size={30} color="rgba(167, 41, 245, 1)" />,
            onClick: (rowData) => {
                navigate(`/events/edit/${rowData.id}`);
            },
        },
    ];

    useEffect(() => {
        setCurrentPage(0);
    }, [searchTerm]);

    const handleChange = (event) => {
        const value = event.target.value;
        setSearchTerm(value);
        setCurrentPage(1);
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
        localStorage.setItem("ListCurrentPageEventsPast", page);
    };



    const fetchAllData = async () => {
        try {
            const response = await api.get(`/events/past`, {
                params: {
                    records: 9999999999,
                },
            });


            return response.data.data.map((item) => ({
                id: item._id,
                Venue: item.venue_name,
                Name: item.name,
                Category: item.tags.map((tag) => tag).join(", "),
                Location: item.location,
                'Date': parseDate(item.date),
                Dress: item.dress,
                Music: item.music.map((music) => music).join(", "),
                Address: item.address,
                About:
                    item?.description?.length > 50
                        ? `${item.description.slice(0, 50)}...`
                        : item.description,
                Likes: item.number_of_saves,
                "Guestlist": item.check_ins,
            }));

        } catch (error) {
            console.log(`Error fetching all data: ${error}`);
            return [];
        }
    };


    useEffect(() => {
        const storedPage = localStorage.getItem("ListCurrentPageEventsPast");

        setCurrentPage(Number(storedPage) || 1);
    }, []);


    const sortColumnsMapping = {
        Name: "name",
        Category: "tags",
        Music: "music",
    };

    const handleSort = (columnName) => {
        const backendColumnName = sortColumnsMapping[columnName];
        if (sortBy === backendColumnName) {
            setSortDirection(sortDirection === "asc" ? "desc" : "asc");
        } else {
            setSortBy(backendColumnName);
            setSortDirection("asc");
        }
    };

    return (
        isSearching ? (
            <LoadingPage />
        ) : (
            <TableColumn
                hasHeader={true}
                columns={headings}
                data={events}
                sortableColumns={sorts}
                hasExport={true}
                hasSearch={true}
                totalPages={totalPages}
                currentPage={currentPage}
                onPageChange={handlePageChange}
                records={records}
                actions={actions}
                inputSearchProps={{ value: searchTerm, onChange: handleChange }}
                fetchAllData={fetchAllData}
                onSort={handleSort}
                hasModifyHeader={true}
            />
        )
    );
}

export default EventsPast;
